import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import type { IError, IPromoCode, IPromocodeReward } from '@dev-fast/types';
import { PromoStatus, StateActionStatus } from '@dev-fast/types';
import type { Observable } from 'rxjs';
import { merge, tap } from 'rxjs';

import { DEFAULT_PROMO } from './constants/background.const';
import type { PromocodeEngine } from './symbols';
import { PROMOCODE_ENGINE } from './symbols';
import type { BonusAmount, IPromocodeForm } from './types';
import { bonusAmountConstructorUtil, errorMessageConstructorUtil } from './utils';

@Component({
  selector: 'app-promocode',
  templateUrl: './promocode.component.html',
  styleUrls: ['./promocode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromocodeComponent implements OnInit, OnDestroy {
  readonly #promocodeService = inject<PromocodeEngine>(PROMOCODE_ENGINE);
  readonly #destroyRef = inject(DestroyRef);

  readonly isRequestCurrentPromocode = input<boolean>(true);
  readonly defaultPromocode = input<string>(DEFAULT_PROMO);
  readonly isCompact = input<boolean>(false);
  readonly minPayment = input<number | undefined>(undefined);

  promoStatus = signal<PromoStatus>(PromoStatus.DEFAULT);
  promoStatusEnum: typeof PromoStatus = PromoStatus;
  applyActionStatus$: Observable<StateActionStatus.DISPATCH | StateActionStatus.SUCCESS> = this.#promocodeService.applyActionStatus$;
  reward = signal<IPromocodeReward | null>(null);
  errorMessage = signal<string | undefined>(undefined);
  #lastAppliedPromo = signal<string>('');

  promocodeForm: FormGroup<IPromocodeForm> = new FormGroup<IPromocodeForm>({
    code: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required, Validators.minLength(1)],
    }),
  });

  ngOnInit(): void {
    if (this.isRequestCurrentPromocode()) {
      this.#promocodeService.getCurrentPromo();
    }

    merge(
      this.promocodeWatch$,
      this.promocodeErrorWatch$,
      this.promocodeRewardWatch$,
      this.promocodeStatusWatch$,
      this.codeValueChangesWatch$,
    )
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  ngOnDestroy(): void {
    // Для того, чтобы если был введен неверный промокод, а затем переключились на другую вкладку - не висел красный знак при верном коде
    this.#promocodeService.removeActivationErr();
  }

  activatePromocode(): void {
    if (this.promocodeForm.value.code) {
      this.#promocodeService.activatePromoCode(this.promocodeForm.value.code);
    }
  }

  useDefPromo(): void {
    this.promocodeForm.setValue({ code: this.defaultPromocode() });
  }

  activeClasses(status: PromoStatus, isCompact: boolean): string[] {
    const classes: string[] = [status];
    if (isCompact) {
      classes.push('compact');
    }
    return classes;
  }

  showIcon(promoStatus: PromoStatus): string | undefined {
    const code = this.promocodeForm.controls.code.value;
    if (code.length) {
      const valueSame = this.#lastAppliedPromo() === code;
      if (promoStatus === PromoStatus.SUCCESS && valueSame) {
        return 'checked-icon-round-bold';
      }
      if (promoStatus === PromoStatus.ERROR && this.errorMessage()) {
        return 'notification-attention';
      }
    }
    return;
  }

  isButtonDisabled(formInvalid: boolean, actionStatus: StateActionStatus.DISPATCH | StateActionStatus.SUCCESS | null): boolean {
    return formInvalid || actionStatus === StateActionStatus.DISPATCH;
  }

  bonusAmountConstructor(reward: IPromocodeReward | null): BonusAmount {
    return bonusAmountConstructorUtil(reward);
  }

  errorMessageConstructor(errorMessage: string | undefined): string {
    return errorMessageConstructorUtil(errorMessage);
  }

  get promocodeWatch$(): Observable<IPromoCode | null> {
    return this.#promocodeService.promocode$.pipe(
      tap((promocode) => {
        if (promocode && !promocode.rewarded) {
          this.promocodeForm.setValue({ code: promocode?.code || this.defaultPromocode() });
        }
      }),
    );
  }

  get promocodeErrorWatch$(): Observable<IError | null> {
    return this.#promocodeService.promocodeError$.pipe(
      tap((promocodeError) => {
        this.errorMessage.set(promocodeError?.message);
      }),
    );
  }

  get promocodeRewardWatch$(): Observable<IPromocodeReward | null> {
    return this.#promocodeService.promocodeReward$.pipe(
      tap((val) => {
        this.reward.set(val);
      }),
    );
  }

  get promocodeStatusWatch$(): Observable<PromoStatus> {
    return this.#promocodeService.promocodeStatus$.pipe(
      tap((val) => {
        this.promoStatus.set(val);
        if (this.promoStatus() === PromoStatus.SUCCESS) {
          this.#lastAppliedPromo.set(this.promocodeForm.controls.code.value);
          this.promocodeForm.markAsPristine();
        }
      }),
    );
  }

  get codeValueChangesWatch$(): Observable<string> {
    return this.promocodeForm.controls.code.valueChanges.pipe(
      tap((val) => {
        if (val === this.#lastAppliedPromo()) {
          this.#promocodeService.removeActivationErr();
          this.promocodeForm.markAsPristine();
        }
        this.errorMessage.set(undefined);
      }),
    );
  }
}
