import { InjectionToken } from '@angular/core';
import type { NavigationExtras, Params } from '@angular/router';
import type { IError, IPromoCode, IPromocodeReward, PromoStatus, StateActionStatus } from '@dev-fast/types';
import type { Observable } from 'rxjs';

import type { ActivatePromoCode } from '@app/core/state/referrals';

export const PROMOCODE_ENGINE = new InjectionToken('PROMOCODE_ENGINE');

export interface PromocodeEngine {
  readonly applyActionStatus$: Observable<StateActionStatus.DISPATCH | StateActionStatus.SUCCESS>;

  readonly promocodeStatus$: Observable<PromoStatus>;
  readonly promocode$: Observable<IPromoCode | null>;
  readonly promocodeError$: Observable<IError | null>;
  readonly promocodeReward$: Observable<IPromocodeReward | null>;

  activatePromoCode(code: string): ActivatePromoCode;
  getCurrentPromo(): void;
  navigateTo(path: any[], queryParams?: Params | undefined, extras?: NavigationExtras | undefined): void;
  removeActivationErr(): void;
}
